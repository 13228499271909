<template>
  <div class="main">
    <form>
      <div class="preview" id="div-preview">
        <span class="lbl">{{ previewLabel }}</span>
        <img :src="fileUrl" id="img-preview" v-if="fileUrl !== ''" @load="runCanvas" @error="wrongFormat">
      </div>
      <!-- div class="download-btn">
        <a @click="optimize" href="#" class="btn btn-danger" v-if="done && width > 1920">Optimize</a>
        <a @click="download" href="#" class="btn btn-primary" v-if="done">Download</a>
      </div -->
      <div class="form-group js-fileapi-wrapper upload-btn">
        <label for="fileInput" class="upload-btn__txt transparent-btn">Select your file</label>
        <input type="file" name="file" class="form-control-file transparent-btn" id="fileInput" accept="image/*">
        <a class="btn btn-primary btn-lg fake-btn" href="#">{{ btnMessage }}</a>
      </div>
    </form>
    <!-- div class="resize-form" v-if="fileUrl !== ''">
      <label>Width <input type="number" v-model="width"></label>
      <label>Height <input type="number" v-model="height"></label>
      <button @click="resize" class="btn btn-default">Resize</button>
    </div -->
    <div style="clear: both;"></div>
    <canvas id="c" width="320" height="240"></canvas>
  </div>
</template>

<script>
/* global fabric */
import FileAPI from 'fileapi'
export default {
  name: 'Main',
  data() {
    return { 
      fileUrl: '',
      filterBackend: null,
      canvas: null,
      imgInfos: null,
      imgObject: null,
      done: false,
      width: 0,
      height: 0,
      btnMessage: this.$t("message.browse"),
      previewLabel: this.$t("message.drop")
    }
  },
  methods: {
    getDataUrl (file) {
      this.killFileUrl()
      let url = URL.createObjectURL(file)
      return url
    },
    handleFile (event) {
      let file = FileAPI.getFiles(event)
      if (typeof file === "object") {
        document.getElementById('div-preview').className = 'preview loading'
        this.previewLabel = this.$t("message.loading")
        this.btnMessage = this.$t("message.loading")
        this.fileUrl = this.getDataUrl(file[0])
      }
    },
    getSizeImg (e) {
      let img = e.target
      this.imgInfos = {
        width: img.clientWidth,
        height: img.clientHeight
      }
      this.canvas.setWidth(this.imgInfos.width)
      this.canvas.setHeight(this.imgInfos.height)
      this.width = this.imgInfos.width
      this.height = this.imgInfos.height
      this.resizeCanvas()
      document.getElementById('div-preview').className = 'preview done'
      this.previewLabel = ''
    },
    runCanvas (e) {
      this.getSizeImg(e)
      this.canvasImgLoad(this.canvas, this.imgInfos)
    },
    killFileUrl () {
      if (this.fileUrl !== '') {
        URL.revokeObjectURL(this.fileUrl)
      }
    },
    canvasImgLoad (canvas, imgInfos) {
      let _this = this
      canvas.getObjects().forEach((o) => {
        canvas.remove(o)
      })
      fabric.Image.fromURL(this.fileUrl, function (oImg) {
        oImg.set({left: 0,top: 0, width: imgInfos.width, height: imgInfos.height})
        oImg.hasRotatingPoint = false
        oImg.hasControls = false
        oImg.hasBorders = false
        oImg.lockSkewingY = true
        oImg.lockSkewingX = true
        oImg.lockScalingY = true
        oImg.lockScalingX = true
        oImg.lockScalingFlip = true
        oImg.lockRotation = true
        oImg.lockMovementX = true
        oImg.lockMovementY = true
        canvas.add(oImg)
        _this.imgObject = oImg
        _this.done = true
        _this.optimize()
      })
    },
    initCanvas () {
      this.canvas = new fabric.Canvas('c')
      this.canvas.renderOnAddRemove = true
      this.canvas.backgroundColor = '#FFFFFF'
    },
    resize () {
      this.resizeImage(this.width, this.height)
      this.resizeCanvas()
    },
    resizeImage (width, height) {
      let c = this.canvas
      let o = c.item(0)
      o.scaleX = (width / this.imgInfos.width)
      o.scaleY = (height / this.imgInfos.height)
      o.setCoords()
      c.setWidth(width)
      c.setHeight(height)
      c.calcOffset()
      c.renderAll()
      this.width = width
      this.height = height
      this.done = true
    },
    resizeCanvas () {
      let container = document.getElementsByClassName('main-container')[0]
      let availableWidth = container.offsetWidth
      let imgRatio = this.imgInfos.height / this.imgInfos.width
      if (availableWidth < this.width) {
        document.getElementById('c').style.width = availableWidth + 'px'
        document.getElementById('c').style.height = (availableWidth * imgRatio) + 'px'
        document.getElementsByClassName('upper-canvas')[0].style.width = availableWidth + 'px'
        document.getElementsByClassName('upper-canvas')[0].style.height = (availableWidth * imgRatio) + 'px'
        document.getElementsByClassName('canvas-container')[0].style.width = availableWidth + 'px'
        document.getElementsByClassName('canvas-container')[0].style.height = (availableWidth * imgRatio) + 'px'
      } else {
        document.getElementById('c').style.width = this.width + 'px'
        document.getElementById('c').style.height = (this.width * imgRatio) + 'px'
        document.getElementsByClassName('upper-canvas')[0].style.width = this.width + 'px'
        document.getElementsByClassName('upper-canvas')[0].style.height = (this.width * imgRatio) + 'px'
        document.getElementsByClassName('canvas-container')[0].style.width = this.width + 'px'
        document.getElementsByClassName('canvas-container')[0].style.height = (this.width * imgRatio) + 'px'
      }
    },
    download () {
      this.done = false
      let dataURL = this.canvas.toDataURL({
        format: 'jpeg',
        quality: 0.8
      })
      let dlLink = document.createElement('a')
      dlLink.download = 'myfile.jpg'
      dlLink.href = dataURL
      dlLink.target = '_blank'
      dlLink.dataset.downloadurl = ['image/jpeg', dlLink.download, dlLink.href].join(':')
      document.body.appendChild(dlLink)
      dlLink.click()
      document.body.removeChild(dlLink)
      setTimeout(function () {
        this.done = true
      }, 500)
    },
    optimize () {
      if (this.width > 1920) {
        this.height = parseInt(1920 * (this.height / this.width))
        this.width = 1920
        this.resize()
      }
      this.download()
      this.btnMessage = this.$t("message.browse")
    },
    wrongFormat () {
      alert(this.$t("message.wrongfile"))
      document.getElementById('div-preview').className = 'preview'
      this.previewLabel = this.$t("message.drop")
      this.btnMessage = this.$t("message.browse")
    }
  },
  mounted(){
    let _this = this
    window.FileAPI = {}
    let fileInput = document.getElementById('fileInput')
    FileAPI.event.on(fileInput, 'change', this.handleFile)
    if (FileAPI.support.dnd) {
      FileAPI.event.dnd(document.getElementById('app'), function (over){
        document.getElementById('app').style.backgroundColor = over ? '#FAFAFA': '';
      }, function(file) {
        document.getElementById('div-preview').className = 'preview loading'
        _this.fileUrl = _this.getDataUrl(file[0])
      })
    }
    this.initCanvas()
    if (this.fileUrl !== '') {
      window.addEventListener('resize', this.resizeCanvas)
    }
    // Worst trick ever...
    setInterval(() => {
      this.btnMessage = this.$t("message.browse")
    }, 1000)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  color: #334;
}
.main {
  margin-top: 15px;
}
.preview {
  display: block;
  position: absolute;
  top: -200px;
  float: left;
  width: 100px;
  height: 100px;
  background: #FAFAFA;
  border: 1px solid #DDD;
}
.preview img {
  opacity: 0;
  width: auto;
  height: auto;
}
.preview span.lbl {
  display: flex;
  height: 100px;
  width: 100px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.preview.done span.lbl{
  display: none;
}
.preview.done {
  overflow: hidden;
  display: none;
  align-items: center;
  justify-content: center;
}
.preview.done img {
  max-width: 100px;
  max-height: 100px;
  opacity: 1;
}
.upload-btn__txt {
  font-weight: 900;
}
.transparent-btn {
  opacity: 0;
  position: absolute;
  height: 50px;
  width: 100%;
  cursor: pointer;
}
.fake-btn {
  display: inline-block;
  width: 100%;
  color: #fff;
  background-color: #0889ee;
  border-radius: 0;
}
.upload-btn {
  height: 50px;
}
.upload-btn {
  display: block;
  width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .preview{
    position: static;
    top: 0;
  }
  .preview.done {
    display: flex;
  }
  .upload-btn {
    margin-left: 120px;
    margin-right: 120px;
    height: 102px;
    width: calc(100% - 240px);
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
  }
  .download-btn {
    float: right;
    width: 100px;
  }
}
</style>