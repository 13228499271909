import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'

Vue.config.productionTip = false

Vue.use(VueI18n)

// Ready translated locale messages
const messages = {
  en: {
    message: {
      subtitle: 'Downsize a photo to 1920px and saves it as a JPEG.',
      drop: 'Drop a file here',
      browse: 'Browse your photos',
      loading: 'Please wait…',
      wrongfile: 'This app can only downsize JPEG, GIF or PNG files.',
      credits: 'Made and hosted by <a href="https://www.onlinecreation.me" target="_blank"><img src="https://www.onlinecreation.me/favicon.png"> OnlineCreation.me</a>, french hosted CMS.'
    }
  },
  ja: {
    message: {
      subtitle: '1920ピクセルに縮小、JPEGにエクスポート。',
      drop: 'ここにファイルをドロップ',
      browse: '写真を閲覧',
      loading: 'お待ちください',
      wrongfile: 'JPG、GIF、PNGのみ縮小できます。',
      credits: 'Made and hosted by <a href="https://www.onlinecreation.me" target="_blank"><img src="https://www.onlinecreation.me/favicon.png"> OnlineCreation.me</a>.'
    }
  },
  fr: {
    message: {
      subtitle: 'Réduit une photo à 1920px et l\'enregistre en JPEG.',
      drop: 'Placez un fichier ici',
      browse: 'Parcourez vos photos',
      loading: 'Veuillez patienter…',
      wrongfile: 'Cette application ne peut réduire que des fichiers JPEG, GIF ou PNG.',
      credits: 'Créé et hébergé par <a href="https://www.onlinecreation.me" target="_blank"><img src="https://www.onlinecreation.me/favicon.png"> OnlineCreation.me</a>, création de site internet.'
    }
  }
}

let findLocale = function() {
  let locale = 'en'
  if (window.localStorage.getItem('punchi-locale')) {
    locale = window.localStorage.getItem('punchi-locale')
  } else {
    let language = (window.navigator.userLanguage || window.navigator.language || window.navigator.languages[0]).substring(0 ,2).toLowerCase()
    if (language === 'fr' || language === 'ja' || language === 'jp') {
      locale = language
    }
  }
  return locale
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: findLocale(),
  messages
})

new Vue({
  render: h => h(App),
  i18n
}).$mount('#app')
