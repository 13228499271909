<template>
  <div id="app">
    <div class="head">
      <div class="container">
        <svg aria-hidden="true" data-prefix="fal" data-icon="feather" class="svg-inline--fa fa-feather fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="color: #fff; width: 64px; float: left; margin-right: 15px;"><path fill="currentColor" d="M467.11 44.89C438.25 16.03 401.61 0 361.61 0c-46.7 0-97.96 21.85-146.76 70.65l-85.74 85.74c-79.32 79.32-71.83 210.7-53.54 257.4L4.69 484.66A15.962 15.962 0 0 0 0 495.98C0 504.52 6.87 512 16.02 512c4.1 0 8.2-1.56 11.32-4.69l70.93-70.93c17.2 6.76 45.79 12.08 79.23 12.08 57.18 0 128.03-15.48 178.11-65.56l85.74-85.74c90.6-90.61 88.31-189.72 25.76-252.27zM151.76 179.04L237.5 93.3c40.08-40.08 83-61.27 124.11-61.27 30.97 0 59.62 12.28 82.85 35.51 42.32 42.32 45.75 100.1 13.13 156.69H310.42l37.24-37.24c6.26-6.26 6.26-16.39 0-22.65s-16.39-6.26-22.65 0L101.19 388.16c-9.9-43.33-13.3-145.26 50.57-209.12zm25.74 237.38c-23.3 0-40.97-2.79-53.49-5.79l58.75-58.75 158.19.37-7.99 7.99c-48.88 48.88-118.38 56.18-155.46 56.18zm195.38-96.09H214.32l64.07-64.07h156.34c-5.11 6.12-61.85 64.07-61.85 64.07z"></path></svg>
        <h1>Punchi.me</h1>
        <p class="lead">{{ $t("message.subtitle") }}</p>
      </div>
    </div>
    <div class="container main-container">
      <Main />
    </div>
    <div class="statusbar">
      <div class="container">
        <svg style="width: 25px; margin-right: 5px;" aria-hidden="true" data-prefix="fas" data-icon="cloud-rainbow" class="svg-inline--fa fa-cloud-rainbow fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M430.8 304.9c9.9 5.6 19 12.4 26.9 20.3C487.1 292.8 529 272 576 272v-64c-61 0-116.3 24.6-156.8 64.3 5.7 9.9 9.8 20.9 11.6 32.6zm-31.6 20.7c.2-1.9.8-3.7.8-5.6 0-35.3-28.7-64-64-64-12.6 0-24.2 3.8-34.1 10-17.6-38.9-56.5-66-101.9-66-61.9 0-112 50.1-112 112 0 3 .7 5.8.9 8.7C39.2 324.4 0 365.4 0 416c0 53 43 96 96 96h272c53 0 96-43 96-96 0-42-27.2-77.4-64.8-90.4zm-160.6-152c21 5.9 40.4 16.5 56.6 31C359.6 119.4 461.3 64 576 64V0C437.1 0 314.2 68.7 238.6 173.6zm87.2 51.5c7.4-.8 36.7-5.4 68.4 18.9 46.4-46.8 110.7-76 181.8-76v-64c-101.3 0-191.6 47.4-250.2 121.1z"></path></svg>
        <span class="credits" v-html="$t('message.credits')"></span> 
        <span class="languages"><a href="#" @click="switchToEnglish()">&#127482;&#127480; English</a> <a href="#" @click="switchToJapanese()">&#127471;&#127477; 日本語</a> <a href="#" @click="switchToFrench()">&#127467;&#127479; Français</a></span>
      </div>
    </div>
  </div>
</template>

<script>
import Main from './components/Main.vue'

export default {
  name: 'app',
  components: {
    Main
  },
  methods: {
    switchTo (lang) {
      this.$i18n.locale = lang
      window.localStorage.setItem('punchi-locale', lang)
    },
    switchToEnglish() {
      this.switchTo('en')
    },
    switchToJapanese() {
      this.switchTo('ja')
    },
    switchToFrench() {
      this.switchTo('fr')
    }
  }
}
</script>

<style>
#app {
  min-height: 100vh;
  overflow: hidden;
}
.statusbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  border-top: 1px solid #F0F0F0;
  background: #FAFAFA;
  color: #666;
  font-family: 'Source sans pro';
}
.statusbar .languages {
  float: right;
}
.statusbar .languages a {
  margin-left: 20px;
  color: #666;
  font-family: 'Source sans pro';
}
.statusbar .credits a {
  color: #84b44c;
}
.head {
  background: #0889ee;
  color: #fff;
  height: 40vh;
  font-family: 'Source Sans Pro';
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.head .container h1 {
  font-size: 4em;
  font-weight: 900;
  margin-bottom: 0;
  line-height: 70px;
  letter-spacing: -3px;
}
.head .container .lead {
  font-weight: 200;
  display: inline-block;
}
.lead {
  margin-bottom: 5px;
}
.canvas-container,
.upper-canvas,
#c {
  position: absolute;
  top: -100000px;
  left: -100000px
}
</style>
